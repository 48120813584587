<template>
  <div class="max-w-2xl">
    <div class="max-w-xs">
      <h2 class="font-semibold tracking-tight text-indigo-700 text-3xl">
        <slot>Võta meiega ühendust</slot>
      </h2>
    </div>
    <div class="mt-8">
      <div class="max-w-lg lg:max-w-none">
        <form @submit.prevent="onSend" class="grid grid-cols-1 gap-y-6">
          <div>
            <label for="instructions-name" class="sr-only">Teie nimi</label>
            <input
              v-model="firstName"
              type="text"
              name="instructions-name"
              id="instructions-name"
              autocomplete="name"
              class="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md transition duration-300 ease-in-out"
              placeholder="Teie nimi"
            />
          </div>
          <div>
            <label for="instructions-email" class="sr-only">E-mail</label>
            <input
              v-model="email"
              id="instructions-email"
              name="instructions-email"
              type="email"
              autocomplete="instructions-email"
              class="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md transition duration-300 ease-in-out"
              placeholder="E-mail"
            />
          </div>
          <div>
            <label for="instructions-message" class="sr-only">Sisu</label>
            <textarea
              v-model="message"
              id="instructions-message"
              name="instructions-message"
              rows="4"
              class="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md transition duration-300 ease-in-out"
              placeholder="Sisu"
            ></textarea>
          </div>
          <div>
            <button
              type="submit"
              class="inline-flex justify-center py-3 px-6 border border-indigo-700 shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-transparent hover:text-indigo-600 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 transition duration-300 ease-in-out"
            >
              Saada
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { addContactFun } from "@/firebase/functions/functions";

export default defineComponent({
  name: "ContactForm",
  emits: {
    sent: () => true,
  },
  setup(_, { emit }) {
    const firstName = ref<string | null>(null);
    const lastName = ref<string | null>(null);
    const email = ref<string | null>(null);
    const phone = ref<string | null>(null);
    const message = ref<string | null>(null);
    const onSend = async () => {
      await addContactFun({
        firstName: firstName.value,
        lastName: lastName.value,
        email: email.value,
        phone: phone.value,
        message: message.value,
      });
      firstName.value = null;
      lastName.value = null;
      email.value = null;
      phone.value = null;
      message.value = null;
      emit("sent");
    };
    return {
      firstName,
      lastName,
      email,
      phone,
      message,
      onSend,
    };
  },
});
</script>

<style scoped></style>
