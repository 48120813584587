<template>
  <LPolygon
    :latLngs="municipality.geo.geometry.coordinates"
    color="#1F51FF"
    :fill="true"
    :fillColor="fillColor"
    :fillOpacity="fillOpacity"
    :weight="2"
    @mouseenter="mouseOver = true"
    @mouseleave="mouseOver = false"
  >
    <LPopup>
      <div class="w-88 sm:w-96 bg-white pt-3 rounded-xl shadow-2xl font-arimo">
        <div class="w-full pb-1 px-4 pt-4">
          <div class="w-full flex justify-between">
            <h3 class="text-xl leading-6 font-semibold text-gray-900">
              {{ municipality.municipality?.name }}
            </h3>
            <span
              class="
                inline-flex
                items-center
                px-2
                h-6
                text-xs
                font-medium
                whitespace-nowrap
                rounded-md
              "
              :class="pillClasses"
            >
              <svg
                class="mr-1.5 h-2 w-2 flex-shrink-0"
                :class="pillDotColor"
                fill="currentColor"
                viewBox="0 0 8 8"
              >
                <circle cx="4" cy="4" r="3" />
              </svg>
              {{ pillText }}
            </span>
          </div>
          <div
            @click="activateHomepage"
            class="mt-1 cursor-pointer text-sm group font-normal inline-flex"
          >
            <span
              class="
                text-gray-700
                group-hover:text-primary-teal
                border-b border-indigo-600
                group-hover:border-primary-teal
                transition
                duration-300
                ease-out
              "
              >Valla koduleht</span
            >
          </div>
        </div>

        <div class="mt-2 grid grid-cols-1">
          <div class="px-4 py-3 border-t border-gray-200">
            <div class="flex items-center space-x-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-9 w-9 text-gray-600 flex-shrink-0"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="0.6"
                  d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                />
              </svg>
              <div>
                <div class="inline-flex items-center">
                  <span
                    class="
                      whitespace-nowrap
                      text-base
                      font-normal
                      text-gray-900
                    "
                  >
                    Elanike arv</span
                  >
                  <span class="ml-2 text-xs text-gray-700"
                    >(Andmed seisuga 01.01.2021)</span
                  >
                </div>
                <div class="block text-2xl font-semibold text-indigo-600">
                  {{ population }}
                </div>
              </div>
            </div>
          </div>

          <div class="px-4 py-3 border-t border-gray-200">
            <div class="flex items-center space-x-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-9 w-9 text-gray-600 flex-shrink-0"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="0.6"
                  d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
                />
              </svg>
              <div>
                <div class="text-base font-normal text-gray-900">Pindala</div>

                <div class="font-semibold text-indigo-600">
                  <span class="text-2xl">{{ area }}</span>
                  <span class="ml-1 text-lg">km2</span>
                </div>
              </div>
            </div>
          </div>

          <div class="w-full flex group rounded-md">
            <div
              @click="activateMunicipality"
              class="
                cursor-pointer
                group
                relative
                flex-1
                inline-flex
                items-center
                justify-center
                py-5
                border border-transparent
                transition
                duration-300
                bg-indigo-600
                group-hover:bg-indigo-700
                overflow-hidden
                rounded-md
                m-1
              "
            >
              <svg
                class="h-20 w-auto absolute -left-2 -top-2"
                viewBox="0 0 219 147"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.6">
                  <path
                    d="M71.6667 34.6233C52.5756 36.7569 34.4748 44.2376 19.4477 56.2047C4.42058 68.1717 -6.92213 84.1386 -13.275 102.268C-19.6279 120.397 -20.7327 139.951 -16.4623 158.681C-12.1919 177.41 -2.71991 194.553 10.8636 208.136C24.4471 221.72 41.59 231.192 60.3194 235.462C79.0487 239.733 98.6032 238.628 116.732 232.275C134.861 225.922 150.828 214.579 162.795 199.552C174.762 184.525 182.243 166.424 184.377 147.333H71.6667V34.6233Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M179.197 102H117V39.8027C131.341 44.8905 144.366 53.1141 155.126 63.874C165.886 74.6339 174.109 87.659 179.197 102V102Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
              </svg>

              <span class="text-base text-white font-medium"
                >Eelarve täitmine</span
              >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="
                  h-6
                  w-6
                  text-white
                  ml-3
                  group-hover:translate-x-2
                  transform
                  transition
                  duration-300
                  ease-in-out
                "
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </LPopup>
    <LTooltip>
      {{ municipality.municipality?.name }}
    </LTooltip>
  </LPolygon>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from "vue";
import { HomeMapMunicipalityDTO } from "@/common/types";
import { LPolygon, LTooltip, LPopup } from "@vue-leaflet/vue-leaflet";
import { useRouter } from "vue-router";
import { RouteName } from "@/router/types";

export default defineComponent({
  name: "HomeMapMunicipality",
  components: { LPolygon, LTooltip, LPopup },
  props: {
    municipality: {
      type: Object as PropType<HomeMapMunicipalityDTO>,
      required: true,
    },
  },
  setup(props) {
    const mouseOver = ref(false);
    const router = useRouter();
    const active = computed(() => props.municipality.municipality?.active);
    const fillColor = computed(() => (active.value ? "#10B981" : "#2F989F"));
    const mouseOverOpacityAddition = computed(() =>
      mouseOver.value ? 0.3 : 0
    );
    const fillOpacity = computed(() =>
      active.value
        ? 0.7 + mouseOverOpacityAddition.value
        : 0 + mouseOverOpacityAddition.value
    );

    const activateMunicipality = () =>
      router.push({
        name: RouteName.MUNICIPALITY_HOME,
        params: { municipalitySlug: props.municipality.geo.uid },
      });

    const activateHomepage = () =>
      window.open(props.municipality.municipality?.homepage, "blank_");

    const population = computed(() =>
      (props.municipality.municipality?.population ?? 0).toLocaleString(
        "fr-FR",
        { maximumFractionDigits: 0 }
      )
    );
    const area = computed(() =>
      (props.municipality.municipality?.area ?? 0).toLocaleString("fr-FR", {
        maximumFractionDigits: 0,
      })
    );

    const pillDotColor = computed(() =>
      active.value ? "text-green-400" : "text-red-400"
    );
    const pillClasses = computed(() =>
      active.value ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800"
    );
    const pillText = computed(() =>
      active.value ? "Liitunud" : "Ei ole liitunud"
    );

    return {
      fillColor,
      fillOpacity,
      mouseOver,
      activateMunicipality,
      activateHomepage,
      population,
      area,
      pillDotColor,
      pillClasses,
      pillText,
    };
  },
});
</script>

<style>
.leaflet-popup-content {
  margin: 0px 0px;
  padding: 0px 0px;
}

@media (max-width: 639.99px) {
  .leaflet-popup-content {
    width: 22rem !important;
  }
}

@media (min-width: 640px) {
  .leaflet-popup-content {
    width: 24rem !important;
  }
}

.leaflet-popup-content-wrapper {
  border-radius: 6px !important;
}

.leaflet-container a.leaflet-popup-close-button  {
  font:28px/16px "Arimo", sans-serif !important;
  width:28px !important;
  height:28px !important;
  padding:6px !important;
}
</style>
