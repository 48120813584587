
import { defineComponent, ref } from "vue";
import { addContactFun } from "@/firebase/functions/functions";

export default defineComponent({
  name: "ContactForm",
  emits: {
    sent: () => true,
  },
  setup(_, { emit }) {
    const firstName = ref<string | null>(null);
    const lastName = ref<string | null>(null);
    const email = ref<string | null>(null);
    const phone = ref<string | null>(null);
    const message = ref<string | null>(null);
    const onSend = async () => {
      await addContactFun({
        firstName: firstName.value,
        lastName: lastName.value,
        email: email.value,
        phone: phone.value,
        message: message.value,
      });
      firstName.value = null;
      lastName.value = null;
      email.value = null;
      phone.value = null;
      message.value = null;
      emit("sent");
    };
    return {
      firstName,
      lastName,
      email,
      phone,
      message,
      onSend,
    };
  },
});
