
import {
  computed,
  defineComponent,
  onMounted,
  ref,
} from "vue";
import { useMunicipalityStore } from "@/store/MunicipalityStore";
import { RouteName } from "@/router/types";



export default defineComponent({
  name: "HomeSearch",

  setup() {
    const municipalitiesStore = useMunicipalityStore();
    const municipalitiesState = municipalitiesStore.getState();
    onMounted(async () => await municipalitiesStore.loadMunicipalities());

    const municipalitySearch = ref<string | null>(null);
    const municipalities = computed(() => municipalitiesState.municipalities);

    const filteredMunicipalities = computed(() => {
      const searchLower = municipalitySearch.value?.toLowerCase();
      if (!searchLower) return municipalities.value;
      return municipalities.value.filter((m) =>
        m.name.toLowerCase().includes(searchLower)
      );
    });

    return {
      municipalitySearch,
      RouteName,
      filteredMunicipalities,
    };
  },
});
