
import { defineComponent, ref, watch } from "vue";
import MModalWrapper from "@/components/common/utils/modal/MModalWrapper.vue";

export default defineComponent({
  name: "MModal",
  components: { MModalWrapper },
  props: {
    toggleClose: {
      type: Boolean,
      required: false,
    },
  },
  emits: {
    onclose: () => true,
  },
  setup(props, { emit }) {
    const isOpen = ref(true);
    const onClose = () => emit("onclose");
    watch(
      () => props.toggleClose,
      () => (isOpen.value = false)
    );
    return { isOpen, onClose };
  },
});
