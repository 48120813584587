<template>
  <div>
    <!-- TODO both options? Mobile search desktop map??? -->
    <HomeSearch v-if="false" />
    <HomeMap />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import HomeSearch from "@/components/home/search/HomeSearch.vue";
import HomeMap from "@/components/home/map/HomeMap.vue";

export default defineComponent({
  name: "Home",
  components: { HomeMap, HomeSearch },
  setup() {
    return {};
  },
});
</script>

<style scoped></style>
