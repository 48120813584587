<template>
  <div class="relative h-screen w-screen">
    <LMap :zoom="7.5" :center="[58.5953, 25.0136]" ref="map" v-if="showMap">
      <LWmsTileLayer
        baseUrl="http://tiles.maaamet.ee/tm/"
        layers="kaart"
        format="image/png"
        layer-type="base"
        :zIndex="1"
        :options="{ maxNativeZoom: 18, maxZoom: 20 }"
      />
      <HomeMapMunicipality
        v-for="pol of Object.keys(polygons)"
        :key="polygons[pol].geo.uid"
        :municipality="polygons[pol]"
      />
      <div
        @click="isContactModal = true"
        class="absolute top-2 right-2 sm:top-4 sm:right-4 w-12 h-12 bg-indigo-600 cursor-pointer rounded-lg text-white hover:bg-indigo-500 hover:text-gray-200 transition duration-300"
        style="z-index: 9998"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-10 w-10 mx-auto mt-1"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
          />
        </svg>
      </div>
    </LMap>
    <MModal
      v-if="isContactModal"
      @onclose="isContactModal = false"
      :toggleClose="toggleContactModalClose"
    >
      <ContactForm @sent="toggleContactModalClose = !toggleContactModalClose" />
    </MModal>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeMount, onMounted, ref } from "vue";
import { LMap, LWmsTileLayer } from "@vue-leaflet/vue-leaflet";
import { useMunicipalityStore } from "@/store/MunicipalityStore";
import { HomeMapMunicipalityDTO } from "@/common/types";
import "leaflet/dist/leaflet.css";
import HomeMapMunicipality from "@/components/home/map/HomeMapMunicipality.vue";
import MModal from "@/components/common/utils/modal/MModal.vue";
import ContactForm from "@/components/common/utils/contact/ContactForm.vue";

export default defineComponent({
  name: "HomeMap",
  components: { ContactForm, MModal, HomeMapMunicipality, LMap, LWmsTileLayer },
  setup() {
    const showMap = ref(false);
    onBeforeMount(async () => {
      window.L = await import("leaflet/dist/leaflet-src.esm");
      showMap.value = true;
    });
    const municipalitiesStore = useMunicipalityStore();
    const municipalitiesState = municipalitiesStore.getState();
    onMounted(async () => await municipalitiesStore.initMunicipalities());
    const municipalities = computed(() => municipalitiesState.municipalities);
    const municipalitiesGeos = computed(
      () => municipalitiesState.municipalitiesGeo
    );

    const polygons = computed<Record<string, HomeMapMunicipalityDTO>>(() =>
      municipalitiesGeos.value.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.uid]: {
            geo: curr,
            municipality: municipalities.value.find((m) => m.uid === curr.uid),
          },
        }),
        {} as Record<string, HomeMapMunicipalityDTO>
      )
    );

    const isContactModal = ref(false);
    const toggleContactModalClose = ref(false);
    return { showMap, polygons, isContactModal, toggleContactModalClose };
  },
});
</script>

<style scoped></style>
