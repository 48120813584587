
import { computed, defineComponent, PropType, ref } from "vue";
import { HomeMapMunicipalityDTO } from "@/common/types";
import { LPolygon, LTooltip, LPopup } from "@vue-leaflet/vue-leaflet";
import { useRouter } from "vue-router";
import { RouteName } from "@/router/types";

export default defineComponent({
  name: "HomeMapMunicipality",
  components: { LPolygon, LTooltip, LPopup },
  props: {
    municipality: {
      type: Object as PropType<HomeMapMunicipalityDTO>,
      required: true,
    },
  },
  setup(props) {
    const mouseOver = ref(false);
    const router = useRouter();
    const active = computed(() => props.municipality.municipality?.active);
    const fillColor = computed(() => (active.value ? "#10B981" : "#2F989F"));
    const mouseOverOpacityAddition = computed(() =>
      mouseOver.value ? 0.3 : 0
    );
    const fillOpacity = computed(() =>
      active.value
        ? 0.7 + mouseOverOpacityAddition.value
        : 0 + mouseOverOpacityAddition.value
    );

    const activateMunicipality = () =>
      router.push({
        name: RouteName.MUNICIPALITY_HOME,
        params: { municipalitySlug: props.municipality.geo.uid },
      });

    const activateHomepage = () =>
      window.open(props.municipality.municipality?.homepage, "blank_");

    const population = computed(() =>
      (props.municipality.municipality?.population ?? 0).toLocaleString(
        "fr-FR",
        { maximumFractionDigits: 0 }
      )
    );
    const area = computed(() =>
      (props.municipality.municipality?.area ?? 0).toLocaleString("fr-FR", {
        maximumFractionDigits: 0,
      })
    );

    const pillDotColor = computed(() =>
      active.value ? "text-green-400" : "text-red-400"
    );
    const pillClasses = computed(() =>
      active.value ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800"
    );
    const pillText = computed(() =>
      active.value ? "Liitunud" : "Ei ole liitunud"
    );

    return {
      fillColor,
      fillOpacity,
      mouseOver,
      activateMunicipality,
      activateHomepage,
      population,
      area,
      pillDotColor,
      pillClasses,
      pillText,
    };
  },
});
