
import { defineComponent, onMounted, ref, watch, unref as v } from 'vue'
// NOTE howto: send open = true (:open="isOpen") in, if you want close,
// then change open to false in parent and listen onclose (@onclose="() => onClose()") event (fires when animations are ended)
export default defineComponent({
  props: {
    open: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const isModal = ref(false)
    onMounted(() => (isModal.value = true))

    watch(
      () => v(props.open),
      () => {
        if (!v(props.open)) {
          isModal.value = false
        }
      }
    )

    return { isModal }
  }
})
