<template>
  <teleport to="#modal-wrapper">
    <MModalWrapper :open="isOpen" @onclose="() => onClose()">
      <div class="block absolute top-0 right-0 pt-4 pr-4">
        <button
          @click="isOpen = false"
          type="button"
          class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
          aria-label="Close"
        >
          <!-- Heroicon name: x -->
          <svg
            class="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <slot></slot>
    </MModalWrapper>
  </teleport>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import MModalWrapper from "@/components/common/utils/modal/MModalWrapper.vue";

export default defineComponent({
  name: "MModal",
  components: { MModalWrapper },
  props: {
    toggleClose: {
      type: Boolean,
      required: false,
    },
  },
  emits: {
    onclose: () => true,
  },
  setup(props, { emit }) {
    const isOpen = ref(true);
    const onClose = () => emit("onclose");
    watch(
      () => props.toggleClose,
      () => (isOpen.value = false)
    );
    return { isOpen, onClose };
  },
});
</script>

<style scoped></style>
