
import { computed, defineComponent, onBeforeMount, onMounted, ref } from "vue";
import { LMap, LWmsTileLayer } from "@vue-leaflet/vue-leaflet";
import { useMunicipalityStore } from "@/store/MunicipalityStore";
import { HomeMapMunicipalityDTO } from "@/common/types";
import "leaflet/dist/leaflet.css";
import HomeMapMunicipality from "@/components/home/map/HomeMapMunicipality.vue";
import MModal from "@/components/common/utils/modal/MModal.vue";
import ContactForm from "@/components/common/utils/contact/ContactForm.vue";

export default defineComponent({
  name: "HomeMap",
  components: { ContactForm, MModal, HomeMapMunicipality, LMap, LWmsTileLayer },
  setup() {
    const showMap = ref(false);
    onBeforeMount(async () => {
      window.L = await import("leaflet/dist/leaflet-src.esm");
      showMap.value = true;
    });
    const municipalitiesStore = useMunicipalityStore();
    const municipalitiesState = municipalitiesStore.getState();
    onMounted(async () => await municipalitiesStore.initMunicipalities());
    const municipalities = computed(() => municipalitiesState.municipalities);
    const municipalitiesGeos = computed(
      () => municipalitiesState.municipalitiesGeo
    );

    const polygons = computed<Record<string, HomeMapMunicipalityDTO>>(() =>
      municipalitiesGeos.value.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.uid]: {
            geo: curr,
            municipality: municipalities.value.find((m) => m.uid === curr.uid),
          },
        }),
        {} as Record<string, HomeMapMunicipalityDTO>
      )
    );

    const isContactModal = ref(false);
    const toggleContactModalClose = ref(false);
    return { showMap, polygons, isContactModal, toggleContactModalClose };
  },
});
