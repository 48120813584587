<template>
  <div
    class="w-full flex flex-wrap"
    :class="filteredMunicipalities.length < 3 ? 'justify-center' : ''"
  >
    <div class="w-full justify-center my-4 inline-flex">
      <div class="w-5/6 md:w-2/3 lg:w-1/2 relative rounded-md shadow-sm">
        <div
          class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
        >
          <!-- Heroicon name: solid/mail -->
          <svg
            class="h-6 w-6 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
        <input
          type="text"
          name="municipality-search"
          id="municipality-search"
          v-model="municipalitySearch"
          class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 md:text-lg lg:text-xl border-gray-300 rounded-md"
          placeholder="Otsi omavalitsust"
        />
      </div>
    </div>
    <router-link
      class="w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 text-center font-medium"
      :class="
          municipality.active
            ? 'text-indigo-600 hover:text-indigo-500'
            : 'text-gray-600 hover:text-gray-500'
        "
      v-for="municipality of filteredMunicipalities"
      :key="municipality.uid"
      :to="{
          name: RouteName.MUNICIPALITY_HOME,
          params: { municipalitySlug: municipality.uid },
        }"
    >
      {{ municipality.name }}
    </router-link>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  ref,
} from "vue";
import { useMunicipalityStore } from "@/store/MunicipalityStore";
import { RouteName } from "@/router/types";



export default defineComponent({
  name: "HomeSearch",

  setup() {
    const municipalitiesStore = useMunicipalityStore();
    const municipalitiesState = municipalitiesStore.getState();
    onMounted(async () => await municipalitiesStore.loadMunicipalities());

    const municipalitySearch = ref<string | null>(null);
    const municipalities = computed(() => municipalitiesState.municipalities);

    const filteredMunicipalities = computed(() => {
      const searchLower = municipalitySearch.value?.toLowerCase();
      if (!searchLower) return municipalities.value;
      return municipalities.value.filter((m) =>
        m.name.toLowerCase().includes(searchLower)
      );
    });

    return {
      municipalitySearch,
      RouteName,
      filteredMunicipalities,
    };
  },
});
</script>

<style scoped></style>
