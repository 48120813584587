<template>
  <div class="fixed inset-0 overflow-y-auto" style="z-index: 10000">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center">
      <transition
        enter-active-class="ease-out duration-300"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="ease-in duration-200"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
        @after-leave="() => $emit('onclose')"
      >
        <div class="fixed inset-0 transition-opacity" v-show="isModal">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
      </transition>

      <!-- This element is to trick the browser into centering the modal content. -->
      <span class="hidden sm:inline-block sm:align-middle"></span>&#8203;

      <transition
        enter-active-class="ease-out duration-300"
        enter-from-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enter-to-class="opacity-100 translate-y-0 sm:scale-100"
        leave-active-class="ease-in duration-200"
        leave-from-class="opacity-100 translate-y-0 sm:scale-100"
        leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
        <div
          class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          v-show="isModal"
        >
          <slot></slot>
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch, unref as v } from 'vue'
// NOTE howto: send open = true (:open="isOpen") in, if you want close,
// then change open to false in parent and listen onclose (@onclose="() => onClose()") event (fires when animations are ended)
export default defineComponent({
  props: {
    open: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const isModal = ref(false)
    onMounted(() => (isModal.value = true))

    watch(
      () => v(props.open),
      () => {
        if (!v(props.open)) {
          isModal.value = false
        }
      }
    )

    return { isModal }
  }
})
</script>

<style scoped></style>
