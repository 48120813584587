
import { defineComponent } from "vue";
import HomeSearch from "@/components/home/search/HomeSearch.vue";
import HomeMap from "@/components/home/map/HomeMap.vue";

export default defineComponent({
  name: "Home",
  components: { HomeMap, HomeSearch },
  setup() {
    return {};
  },
});
